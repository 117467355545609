import React, {Component}       from 'react'
import PropTypes                from 'prop-types'
import EditMark                 from './EditMark'
import SostaOnlineApiService    from '../../../services/SostaOnlineApiService'
import { connect }              from 'react-redux'
import moment                   from 'moment'
import { MARK_EXPIRATION_DATE } from 'libs/settings'
import { scroller }             from 'react-scroll'

class EditMarkContainer extends Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  constructor (props) {
    super(props)
    this.state = {
      mark: {},
      user: {},
      settings: [],
      markRules: {},
      initialValues: {},
      selectedDocuments: [],
      loading: true,
      price: null,
      errors: null,            
      documentTypes: [],
      submitting: false
    }

    this.onVehicleSelected = this.onVehicleSelected.bind(this)
    this.onVehicleAdd = this.onVehicleAdd.bind(this)
    this.onVehicleRemove = this.onVehicleRemove.bind(this)
    this.onVehicleOwnershipTypeChange = this.onVehicleOwnershipTypeChange.bind(this)
    this.onVehiclePowerSupplyChange = this.onVehiclePowerSupplyChange.bind(this)
    this.onDocumentAdd = this.onDocumentAdd.bind(this)
    this.onDocumentRemove = this.onDocumentRemove.bind(this)
    this.onDocumentChange = this.onDocumentChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCloseFileManager = this.onCloseFileManager.bind(this)
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  async fetchInitialData () {
    this.setState({loading: true})
    const userResponse = await SostaOnlineApiService.fetchUserMe()
    const markResponse = await SostaOnlineApiService.fetchMarkMe(this.props.match.params.id)

    if (markResponse.data.status !== 'ACTIVE_EDITABLE' && markResponse.data.status !== 'PENDING_EDITABLE') {
      this.setState({
        loading: false,
        errors: 'Errore! Questo permesso non è modificabile al momento'
      })
      return
    }

    const rules                = await SostaOnlineApiService.fetchMarkRulesByMarkType(markResponse.data.MarkType.id)
    const settingsResponse     = await SostaOnlineApiService.fetchPublicSettings()        
    const documentsTypes       = await SostaOnlineApiService.fetchDocumentTypesByMarkTypeId(markResponse.data.MarkType.id)

    let initialValues = {}    

    for (let index in markResponse.data.MarkDocuments) {

      var markDoc = markResponse.data.MarkDocuments[index]
      initialValues['documentFile-' + index + '-' + markDoc.DocumentId  ] = {
        ...markDoc.Document        
      }
    }

    this.setState({
      user: userResponse.data,
      mark: markResponse.data,
      markRules: rules.data[0],
      documentTypes: documentsTypes.data.map(item => {

        let mandatory = false

        if ( 
          item.MarkTypes && 
          item.MarkTypes.length > 0 && 
          item.MarkTypes[0] && 
          item.MarkTypes[0].MarkTypesDocuments ) 
        {
          mandatory = item.MarkTypes[0].MarkTypesDocuments.mandatory
        } 

        return {
          documentTypeId: item.id,          
          typeName: item.name,
          typeLabel: item.label,
          mandatory: mandatory,
          vehiclePlateNumber: null,
          document: null
        }
      }),
      settings: settingsResponse.data,            
      loading: false,
      initialValues: initialValues,
      selectedDocuments: markResponse.data.MarkDocuments.map(item => ({...item.Document, DocumentType: item.DocumentType, approved: item.approved }))
    }, async () => this.updatePrice())
    
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  getSettingByName (name) {
    const { settings } = this.state
    const foundSettings = settings.filter(item => item.name === name)
    if (foundSettings.length === 0) {
      return null
    }

    return foundSettings[0]
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  async componentDidMount () {
    await this.fetchInitialData()
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  getUserMarksByTypeId (markType) {
    const today = moment()
    return this.state.user.marks.filter(item => {
      const endDate = moment(item.endDate)
      const isExpired = today.diff(endDate, 'days') > 0
      return item.markTypeId === markType.id && !item.ceased && item.active && !item.ceased && !isExpired
    })
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  printVehiclesPowerSupplies (vehicles) {
    let string = ''
    vehicles.map((item, index) => {
      string = string + item.VehiclePowerSupply.id

      if (index < vehicles.length - 1) {
        string = string + ','
      }
    })
    return string
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  async updatePrice () {

    this.setState({errors: null})
    try {

      var mark = this.state.mark 
      
      if ( mark != null ) {
        console.log("mark",mark.id)
        
        var priceData = await SostaOnlineApiService.fetchMarkPriceRecalculate(mark.id)
        .catch( error => {
          console.log("ERROR",error)
          this.setState({errors: 'Errore durante l\'elaborazione dei prezzi'})
        })        

        if ( priceData != null &&  priceData.data != null )
          this.setState({price: priceData.data})
      }
      else {
        this.setState({errors: 'Errore durante l\'elaborazione dei prezzi'})      
      }
    } 
    catch (ex) {
      console.log("ERROR",ex)
      this.setState({errors: 'Errore durante l\'elaborazione dei prezzi'})      
    }
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  onVehicleSelected (vehicleId) {

    if (this.state.markRules.vehicleLimit === this.state.mark.Vehicles.length) {
      return
    }

    let newVehicles = [...this.state.mark.Vehicles]
    var newVehicle = this.state.user.vehicles.filter( ( vehicle  ) => vehicle.id === vehicleId  )

    if ( newVehicle.length > 0 ) {
      newVehicles.push(newVehicle[0])

      this.setState({
        mark: {
          ...this.state.mark,
          Vehicles: newVehicles
        }      
      }, async () => {      
          await this.updatePrice()
        
      })
    }

    scroller.scrollTo( 'step-two', {
      duration: 1000,
      delay: 100,
      smooth: true
    })

  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  onVehicleRemove (index) {
    this.setState({
      mark: {
        ...this.state.mark,
        Vehicles: this.state.mark.Vehicles.filter((_, i) => i !== index)
      }
    }, async () => {
      if (this.state.mark.Vehicles.length > 0) {
        await this.updatePrice()
      } else {
        this.setState({price: null})
      }
    })
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  async onVehicleAdd (vehicle) {
    await this.fetchInitialData()
    this.onVehicleSelected(vehicle.id)   

    scroller.scrollTo( 'step-two', {
      duration: 1000,
      delay: 100,
      smooth: true
    })
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  onVehicleOwnershipTypeChange (e, index) {
    let newVehicles = [...this.state.mark.Vehicles]
    newVehicles[index].VehicleOwnershipType = {id: e.target.value}
    this.setState({
      mark: {
        ...this.state.mark,
        Vehicles: newVehicles
      }
    }, () => this.updatePrice())
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  onVehiclePowerSupplyChange (e, index) {
    let newVehicles = [...this.state.mark.Vehicles]
    newVehicles[index].VehiclePowerSupply = {id: e.target.value}
    this.setState({
      mark: {
        ...this.state.mark,
        Vehicles: newVehicles
      }
    }, () => this.updatePrice())
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  onCloseFileManager() {    
    const {selectedDocuments} = this.state
    const newDocuments = selectedDocuments.filter( (item ) =>  item.id != null && item.id != -1 )
    this.setState({selectedDocuments: newDocuments})
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} document 
   * @param {*} index 
   */
  onDocumentChange (document,index) {    

    const {selectedDocuments} = this.state    
    if ( selectedDocuments.findIndex( doc =>  doc.id == document.id  ) < 0 ) {
      const newDocuments = selectedDocuments.map((item,index1) => {

        if ( index === index1 || item.id === -1  ) {

          this.setState({          
            initialValues: {
              ...this.state.initialValues,
              ['documentFile-' + index + "-" + document.id ] : {
                ...document          
              }
            }
          })

          return document
        } 
        else {        
          return item
        }
      })
      
      this.setState({
        selectedDocuments: [...newDocuments]
      })
    }
    else {
      console.log("ELEMENTO DOPPIO")
      const newDocuments = selectedDocuments.filter( (item ) =>  item.id != null && item.id != -1 )
      this.setState({selectedDocuments: newDocuments})      
    }

    scroller.scrollTo( 'step-final', {
      duration: 1000,
      delay: 100,
      smooth: true
    })
    
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  onDocumentRemove (index) {
    this.setState({selectedDocuments: this.state.selectedDocuments.filter((_, i) => i !== index)})
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  onDocumentAdd () {    
    var docs =  this.state.selectedDocuments.map( doc => { doc.openFileManager = false ; return doc  } )
    docs = [...docs, { 'openFileManager': true , id:-1 } ]
    this.setState({ 'selectedDocuments': docs})

    scroller.scrollTo( 'step-final', {
      duration: 1000,
      delay: 100,
      smooth: true
    })
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  async onSubmit (values) {
    const { submitting, mark, selectedDocuments } = this.state

    if (submitting) {
      return
    }

    try {
      this.setState({submitting: true, errors: null})
      const response = await SostaOnlineApiService.updateMarkMe({
        id: mark.id,
        vehicles: mark.Vehicles,
        documents: selectedDocuments.map(item => item.id)
      })
      if (response.result !== 'ok') {
        this.setState({
          errors: 'Errore durante la richiesta di modifica del permesso',
          submitting: false
        })
      } 
      else {
        this.setState({
          submitting: false
        })
        this.props.history.push('/user-area/marks')
      }
    } 
    catch (e) {
      console.error(e)
      this.setState({submitting: false, errors: e.error || 'Errore imprevisto durante la modifica del permesso'})
    }
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  render () {
    const {
      mark,
      loading,
      errors,
      user,            
      markRules,
      price,
      submitting,
      initialValues,
      documentTypes,
      selectedDocuments,
      settings
    } = this.state

    const markExpirationDate =  ( mark && mark.MarkType && mark.MarkType.id ) ? 
      this.getSettingByName(MARK_EXPIRATION_DATE+mark.MarkType.id) : null
    
    if ( errors && errors.length > 0 ) {
      scroller.scrollTo('errors', {
        duration: 1000,
        delay: 100,
        smooth: true
      })
    }

    return (
      <EditMark
        initialValues={initialValues}
        mark={mark}
        user={user}
        loading={loading}
        markExpirationDate={markExpirationDate}
        errors={errors}        
        markRules={markRules}
        onVehicleSelected={this.onVehicleSelected}                        
        onVehicleAdd={this.onVehicleAdd}
        onVehicleRemove={this.onVehicleRemove}                
        price={price}        
        documents={documentTypes}
        onSubmit={this.onSubmit}        
        submitting={submitting}        
        selectedDocuments={selectedDocuments}
        onDocumentAdd={this.onDocumentAdd}
        onDocumentRemove={this.onDocumentRemove}
        onDocumentChange={this.onDocumentChange}
        onCloseFileManager={this.onCloseFileManager}
        settings={settings}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(EditMarkContainer)
