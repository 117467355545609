import React, { Component } from 'react'
import { reduxForm, Field, change } from 'redux-form'
import SostaOnlineApiService from '../services/SostaOnlineApiService'
import { connect } from 'react-redux'
import DatePickerFormComponent from './redux-form/DatePickerFormComponent'
import moment, { duration } from 'moment'
import { Oval } from 'react-loader-spinner'
import { MARK_TYPE_ELECTRIC, MARK_TYPE_FAMILY, MARK_TYPE_GREEN, MARK_TYPE_HYBRID, PRICE_TYPE_PERSON_PER_HOUSING_UNIT, getMarkExpirationDate } from 'libs/marks'
import { MARK_DURATION, MARK_EXPIRATION_DATE, MARK_ENABLE_START_DATE, MARK_ENABLE_END_DATE , MARK_PRINT_BY_USER, getSettingByName } from 'libs/settings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import * as regularIcons from '@fortawesome/free-regular-svg-icons'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import MarksPersonsPerHouseUnitsModal from './Marks/MarksPersonsPerHouseUnitsModal'
import { isNull } from 'lodash'
import { checkEndDate } from "libs/utils";

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

// Funzione per fare il calcolo della data di scadenza con base nella data di inizio e nell tipo di abbonamento
//function calculateEndDate(startDate, markType) {
//const durationInMonths = markType.durationInMonths   // Ottiene la durata in mesi del tipo di abbonamento dall'oggetto markType
//const endDate = new Date(startDate);   // Crea una nuova istanza di Date basata sulla data di inizio fornita
//endDate.setMonth(endDate.getMonth() + durationInMonths); // Aggiunge la durata in mesi al mese della data di termine
//console.log('funzione calculateEndDate chiamata con successo')
//return endDate; // Restituisce la data di termine calcolata
//}

class BackOfficeUserDetailMarkForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      fetchingMarkTypes: false,
      category: null,
      allMarkTypes: [],
      filteredMarkTypes: [],
      markTypeId: null,
      markRules: {},
      errorFetching: null,
      vehicles: [],
      price: {},
      quantity: 1,
      error: false,
      markTypeDocuments: [],
      selectedMarkType: null,
      selectedMarkTypeCategory: null,
      selectedTotalPrice: 0,
      priceDescr: null,
      enableCash: false,
      enablePagoPa: false,
      enableReclaim: false,
      enableReseller: false,
      paymentDefault: false,
      paymentSelected: false,
      selectedStartDate: null,
      //selectedEndDate: null,  //Nuovo campo nello state per fare lo STORAGE della data di SCADENZA
      settings: [],
      showMarkPerPersonUnits: false
    }

    this.onUserCategorySelected = this.onUserCategorySelected.bind(this)
    this.onMarkTypeSelected = this.onMarkTypeSelected.bind(this)
    this.onVehicleChange = this.onVehicleChange.bind(this)
    this.onAddQuantity = this.onAddQuantity.bind(this)
    this.onRemoveQuantity = this.onRemoveQuantity.bind(this)
    this.countMarks = this.countMarks.bind(this)
    this.onDateChange = this.onDateChange.bind(this)
    this.showMarksPersonsPerHouseUnits = this.showMarksPersonsPerHouseUnits.bind(this)

  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async componentDidMount() {
    try {

      this.formReset()
      this.setState({ loading: true, error: false })
      const markTypes = await SostaOnlineApiService.fetchMarkTypes()

      this.setState({
        allMarkTypes: markTypes.data,
        filteredMarkTypes: markTypes.data,
        loading: false
      })

      const payments = await SostaOnlineApiService.fetchPaymentSettings()

      if (payments.data.length === 0) {
        this.setState({ enableCash: false })
        this.setState({ enablePagoPa: false })
        this.setState({ enableReclaim: false })
        this.setState({ enableReseller: false })
        this.setState({ paymentDefault: false })
        this.props.change('method', "SPORTELLO_POS")
      }
      else {
        this.setState({ enableCash: payments.data.enableCash })
        this.setState({ enablePagoPa: payments.data.enablePagoPa })
        this.setState({ enableReclaim: payments.data.enableReclaim })
        this.setState({ enableReseller: payments.data.enableReseller })
        this.setState({ paymentDefault: payments.data.paymentDefault })
        this.props.change('method', "SPORTELLO_" + payments.data.paymentDefault)
      }

      const settings = await SostaOnlineApiService.fetchPublicSettings()

      if (settings.data != null && settings.data.length > 0) {
        this.setState({ settings: settings.data })
      }

    }
    catch (e) {
      console.error(e)
      this.setState({ error: true, loading: false })
    }
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async fetchFilteredMarkTypes() {
    let cat = this.props.user.categories.filter(item => item.id === parseInt(this.state.category, 10))
    if (cat.length > 0) {
      this.setState({ fetchingMarkTypes: true })

      const markTypes = await SostaOnlineApiService.fetchMarkTypesByCategory(cat[0].id)      
      this.setState({
        filteredMarkTypes: markTypes.data,
        fetchingMarkTypes: false,
        rules: {},
        price: {}
      })
    }
  }

  /**
   * --------------------------------------------------------------
   */
  formReset() {

    this.setState({
      priceDescr: null,
      errorFetching: null,
      error: false
    })

    if (this.props.onResetMarkSubmitForm != null)
      this.props.onResetMarkSubmitForm()


  }

  /**
   * --------------------------------------------------------------
   * @param {*} e 
   */
  onUserCategorySelected(e) {
    this.formReset()

    var selectedMarkTypeCategory = this.props.user.categories.find((category) => category.id == e.target.value)
    //console.log("category",e.target.value)
    //console.log("selectedMarkTypeCategory",selectedMarkTypeCategory)

    this.setState(
      {
        category: e.target.value,
        selectedMarkTypeCategory: selectedMarkTypeCategory
      }, () => { this.fetchFilteredMarkTypes() }
    )
  }

  /**
   * --------------------------------------------------------------
   * @param {*} e 
   * @returns 
   */
  async onMarkTypeSelected(e) {

    this.formReset()

    try {
      const rules = await SostaOnlineApiService.fetchMarkRulesByMarkType(e.target.value)
      if (rules.data.length === 0) {
        this.setState({
          errorFetching: "Errore nella ricezione delle regole del Permesso",
          markRules: {},
          markTypeId: null,
          selectedMarkType: null,
          markTypeDocuments: []
        }, () => {
          this.updatePrice()
        })

        return
      }
      //console.log(rules.data[0].marksLimit);
            
      const today = moment()     

      let activeMarks = this.props.user.marks.filter(item => item.active === true && item.ceased === false && item.archived === false && today.diff(item.endDate, 'days') <= 0)
      let newArray = []

      activeMarks.map(element => {
        if (element.MarkType.id == e.target.value) {
          newArray.push(element)
        }
      });
      //Verifico se l'utente ha già raggiunto il limite massimo di permessi attivi di quel tipo
      if (newArray.length >= rules.data[0].marksLimit) {
        this.setState({
          markLimitReached: true,
          errors: 'L\'utente ha già superato il numero massimo di permessi attivi per il suo tipo di categoria utente'
        })
        return
      }

      if (rules.data[0].vehicleLimit > 0 && this.state.vehicles.length === 0) {
        this.setState({ vehicles: [''] })
      }

      let cat = this.props.user.categories.filter(item => item.id === parseInt(this.state.category, 10))

      if (cat.length <= 0) {

        this.setState({
          markRules: {},
          markTypeId: null,
          selectedMarkType: null,
          markTypeDocuments: [],
          markLimitReached: false,
        }, () => {
          this.updatePrice()
        })

        return
      }

      const markType = this.state.allMarkTypes.filter(item => item.id + '' === e.target.value + '')[0]

      if (markType.virtualizable) {
        this.props.change('virtual', true)
      }

      if (this.state.category && markType && markType.Categories.length > 0) {
        const foundedMarkTypeCategory = markType.Categories.filter(item => {
          return item.id + '' === this.state.category + ''
        })

        if (foundedMarkTypeCategory.length > 0 && foundedMarkTypeCategory[0].MarkTypeCategories) {
          this.props.change('circolazione', foundedMarkTypeCategory[0].MarkTypeCategories.circolazione)
          this.props.change('periodo', foundedMarkTypeCategory[0].MarkTypeCategories.periodo)
          this.props.change('sosta', foundedMarkTypeCategory[0].MarkTypeCategories.sosta)
          this.setState({
            selectedMarkTypeCategory: foundedMarkTypeCategory[0].MarkTypeCategories,
          })
        }
      }

      this.setState({
        markRules: rules.data[0],
        markTypeId: e.target.value,
        selectedMarkType: markType,
        markTypeDocuments: markType.DocumentTypes,
        markLimitReached: false,
      }, () => {
        // Imposta la data di scadenza con base nell tipo di abbonamento
        //Questo blocco di codice imposta essenzialmente lo stato del componente con informazioni sul tipo di abbonamento selezionato, imposta automaticamente la data di inizio dell'abbonamento in base alla data di scadenza e per ultimo aggiorna il prezzo
        //const expirationDate = getMarkExpirationDate(markType);
        //console.log("Expiration Date:", expirationDate);//Console per vedere se expiration date e valido


        //this.setState({ selectedStartDate: expirationDate });
        //this.props.change('selectedStartDate', expirationDate);

        //Calcolo e impostazione della data di scadenza nello state
        //const endDate = calculateEndDate(expirationDate, markType);// Calcola la data di termine in base alla data di scadenza e al tipo di abbonamento 
        //console.log("End Date:", endDate);//Console per vedere se End date e valido


        //this.setState({ selectedEndDate: endDate });// Imposta la data di termine nello stato del componente
        //this.props.change('selectedEndDate', endDate);// Aggiorna il valore del campo 'selectedEndDate' nel componente del genitore utilizzando Redux Form

        this.updatePrice()
      })

    }
    catch (ex) {
      this.setState({
        markRules: {},
        markTypeId: null,
        selectedMarkType: null,
        markTypeDocuments: [],
        errorFetching: "Errore nella ricezione dati del tipo permesso"
      }, () => {
        this.updatePrice()
      })
    }
  }

  //--------------------------------------------------------------------------

  setPayment(e) {
    var stringa = e.target.value.split("_");
    this.setState({ paymentSelected: stringa[1] })
    //console.log(this.state.paymentSelected)
    this.props.change('method', stringa)
  }

  //--------------------------------------------------------------------------

  verifyChecked(whose) {
    if (this.state.paymentSelected == whose) {
      //console.log ( "BackOfficeUserDetailEditMarkForm - this.state.paymentSelected == whose", this.state.paymentSelected, "=", whose)
      //console.log ( "BackOfficeUserDetailEditMarkForm - this.state.paymentSelected == whose", this.state.paymentSelected, "=", whose)
      return this.state.paymentSelected == whose
    } else if (this.state.paymentSelected == false && whose == this.state.paymentDefault) {
      //console.log ( "BackOfficeUserDetailEditMarkForm - this.state.paymentSelected == false", this.state.paymentSelected)
      return true
    } else {
      //console.log ( "BackOfficeUserDetailEditMarkForm - this.state.paymentSelected ", this.state.paymentSelected)
      return false
    }
  }

  /**
   * --------------------------------------------------------------
   * @param {*} e 
   * @param {*} index 
   */
  onVehicleChange(e, index) {
    this.formReset()
    let newVehicles = [...this.state.vehicles]
    newVehicles[index] = e.target.value
    this.setState({ vehicles: newVehicles }, () => this.updatePrice())
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  async updatePrice() {
    try {
      if (this.state.selectedMarkType == null) {
        return
      }

      let price = 0

      let calculatedPrice = await SostaOnlineApiService.fetchMarkPrice(
        this.props.user.id,
        this.state.selectedMarkType.id,
        this.state.selectedMarkTypeCategory.CategoryId,
        this.printVehicles(this.state.vehicles.filter(item => item !== '')),
        null,
        this.state.selectedStartDate
      )
        .catch(error => {
          console.log("ERROR", error)

          var priceDescr = ''

          if (error.result == "ko" && error.error != null) {
            priceDescr = error.error
          }

          this.setState({
            errorFetching: "Errore durante il calcolo del prezzo",
            priceDescr: priceDescr
          })
        })

      if (calculatedPrice == null || calculatedPrice.data == null) {
        this.setState({
          errorFetching: "Errore durante il calcolo del prezzo",
        })
      }
      else {
        price = parseFloat(calculatedPrice.data.price).toFixed(2)
        this.setState({
          price: price,
          priceDescr: calculatedPrice.data.reasons[0],
          selectedTotalPrice: price,
          errorFetching: null
        })
        this.props.change('total', price)
      }

    }
    catch (ex) {
      console.log("ERROR", ex)

      this.setState({
        errorFetching: "Errore durante il calcolo del prezzo "
      })

    }
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  onAddQuantity() {
    this.formReset()
    if (this.state.quantity >= 99) {
      return
    }

    this.props.dispatch(change('backofficeUserDetailMark', 'quantity', this.state.quantity + 1))
    this.setState({ quantity: this.state.quantity + 1 }, () => this.updatePrice())
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  onRemoveQuantity() {
    this.formReset()
    if (this.state.quantity <= 1) {
      return
    }

    this.props.dispatch(change('backofficeUserDetailMark', 'quantity', this.state.quantity - 1))
    this.setState({ quantity: this.state.quantity - 1 }, () => this.updatePrice())
  }

  /**
   * --------------------------------------------------------------
   * @param {*} areas 
   * @returns 
   */
  printAreas(areas) {
    let string = ''
    areas.map((item, index) => {
      string = string + item.code

      if (index < areas.length - 1) {
        string = string + ', '
      }
    })
    return string
  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicles 
   * @returns 
   */
  printVehicles(vehicles) {
    let string = ''
    vehicles.map((item, index) => {
      string = string + item

      if (index < vehicles.length - 1) {
        string = string + ','
      }
    })
    return string
  }

  /**
   * --------------------------------------------------------------
   * @param {*} markTypeId 
   * @returns 
   */
  countMarks(markTypeId) {
    return this.props.user.marks.filter(item => item.id === markTypeId).length
  }

  /**
   * --------------------------------------------------------------
   * @param {*} data 
   */
  async onDateChange(data) {
    this.formReset()
    await this.setState({ selectedStartDate: data })
    this.updatePrice()

  }

  /**
   * --------------------------------------------------------------
   * @param {*} date 
   * @returns 
   */
  checkSelectedStartDate() {

    var {
      selectedMarkType,
      settings,
      selectedStartDate,
    } = this.state

    var disable = false

    if (selectedMarkType == null || selectedStartDate == null || settings == null)
      return disable

    var markDuration = getSettingByName(settings, MARK_DURATION + selectedMarkType.id)
    var enableStartDate = getSettingByName(settings, MARK_ENABLE_START_DATE)

    //console.log('SelectedMarkTypeID:', selectedMarkType.id)
    //console.log('selectedMarkType:', selectedMarkType)
    if (enableStartDate == null) {
      enableStartDate = new Object()
      enableStartDate.name = MARK_ENABLE_START_DATE
      enableStartDate.value = "0"
    }


    if (selectedMarkType.type == MARK_TYPE_FAMILY) {
      var duration = parseInt(markDuration.value, 10)
      if (moment(selectedStartDate).add(duration, 'days').isBefore(moment(), 'days')) {
        disable = true
      }
    }

    // Ottiene la data di oggi
    const currentDate = moment();

    //ID marktype FERROVIARIO MENSILE:19
    //ID marktype FERROVIARIO ANNUALE:20
    //Verifica se la data scelta è un giorno prima dell momento attuale
    const { name } = selectedMarkType
    const nameHasFerrovi = name.toUpperCase().includes('FERROVI')
    //console.log('name:',name)
    //console.log('nameHasFerrovi:',nameHasFerrovi)

    if (moment(selectedStartDate).isBefore(currentDate, 'day') && selectedMarkType.type != MARK_TYPE_FAMILY && nameHasFerrovi === false) {
      disable = true;
    }

    //Verifica se la data scelta e lo current date o posteriore al momento attuale
    if (moment(selectedStartDate).isSameOrAfter(currentDate, 'day')) {
      disable = false;
    }
    //console.log('selectedMarkType:', selectedMarkType)

    return disable
  }

  calculateEndDate = () => {
    const { selectedStartDate, selectedMarkType, settings } = this.state;
    //console.log('this.state:', this.state)

    if (selectedStartDate && selectedMarkType && settings) {
      const permessoId = selectedMarkType.id
      //console.log('selectedMarktypeNAME:',selectedMarkType.name )
      const markDurationByDays = getSettingByName(settings, MARK_DURATION + permessoId);
      const markDurationByDate = getSettingByName(settings, MARK_EXPIRATION_DATE + permessoId);

      console.log('markDurationByDate: ' + markDurationByDate);
      console.log('markDurationByDate: ' , markDurationByDate);
      //markDurationByDate: Object { id: 103, name: "MarkExpirationDate_58", value: "2022-12-31T15:10:33.000Z", createdAt: "2024-08-21T14:11:54.000Z", updatedAt: "2024-08-29T11:10:05.000Z" }

      if (markDurationByDate !== null) {
        //E' una DATA
        let dataInvers = markDurationByDate.value.split('T')[0].split('-');
        dataInvers = dataInvers[2]+"/"+dataInvers[1]+"/"+dataInvers[0];
        //const endDate = moment(markDurationByDate.value)
        // return endDate.format('DD/MM/YYYY')
        return checkEndDate(dataInvers)
      } else {
        //E' un numero        
        const endDate = moment(selectedStartDate).add(parseInt(markDurationByDays.value, 10), 'days');
        return endDate.format('DD/MM/YYYY')
        //const endDate = moment(selectedStartDate).add(markDuration, 'days');
      }

    }
    return '';
  };


  /**
   * --------------------------------------------------------------
   * @param {*} markTypeId 
   */
  showMarksPersonsPerHouseUnits(show) {
    this.setState({ showMarkPerPersonUnits: show })
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  isMarksPersonsPerHoursActive() {
    var markType = this.state.selectedMarkType
    var isActive = false

    if (markType != null && markType.Prices != null && markType.Prices.length > 0) {

      var filteredPrices = markType.Prices.filter((price) => price.type == PRICE_TYPE_PERSON_PER_HOUSING_UNIT)

      if (filteredPrices != null && filteredPrices.length > 0)
        isActive = true
    }

    return isActive
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render() {
    const {
      handleSubmit,
      settings,
      markCreateError,
      user
    } = this.props

    const {
      selectedMarkType,
      selectedMarkTypeCategory,
      errorFetching,
      priceDescr,
      showMarkPerPersonUnits,
      error,
      vehicles
    } = this.state

    var markPrintByUser = getSettingByName(settings, MARK_PRINT_BY_USER)
    var markEnableStartDate = getSettingByName(settings, MARK_ENABLE_START_DATE)

    if (this.state.loading) {
      return (
        <div>
          <div className="row"><h3>Caricamento in corso...</h3></div>
          <div className="row" style={{ padding: "2em", height: "10em" }}>
            <Oval secondaryColor="#02afff" color="#174c88" height={40} width="100%" />
          </div>
        </div>
      )
    }

    if (error) {
      return (<div className="text-danger">Errore di ricezione dati</div>)
    }

    var userVehicles = user.vehicles

    if (selectedMarkType && selectedMarkType.type === MARK_TYPE_HYBRID) {
      userVehicles = user.vehicles.filter(item => item.VehiclePowerSupply.hybrid)
    }
    else if (selectedMarkType && selectedMarkType.type === MARK_TYPE_ELECTRIC) {
      userVehicles = user.vehicles.filter(item => item.VehiclePowerSupply.electric)
    }
    else if (selectedMarkType && selectedMarkType.type === MARK_TYPE_GREEN) {
      userVehicles = user.vehicles.filter(item => (item.VehiclePowerSupply.hybrid || item.VehiclePowerSupply.electric))
    }

    //const { selectedStartDate, selectedEndDate } = this.props;


    //const markType = this.state.allMarkTypes.filter(item => item.id + '' === e.target.value + '')[0]
    //var markDuration    = getSettingByName(settings,MARK_DURATION+selectedMarkType.id )
    //var duration   =parseInt(markDuration.value,10)
    const endDate = this.calculateEndDate();

    return (
      <React.Fragment>
        <form onSubmit={handleSubmit}>
          <div className="box">

            <div className="row form-group">
              <div className="col-md-6 text-left">
                <label>Seleziona Categoria</label>
                <Field onChange={this.onUserCategorySelected} name="categoryId" className="form-control" component="select" required>
                  <option value={''}>Seleziona Categoria Utente</option>
                  {this.props.user.categories.map((item, index) =>
                    <option key={item.id + '-' + index} value={item.id}>{item.label.charAt(0).toUpperCase() + item.label.slice(1)}</option>
                  )}
                </Field>
              </div>
              {selectedMarkTypeCategory &&
                <div className="col-md-6 text-left">
                  <label>Seleziona Tipo Permesso</label>
                  <Field onChange={this.onMarkTypeSelected} name="markTypeId" className="form-control" component="select" required>
                    {this.state.filteredMarkTypes.length > 0 && <option value={''}>Seleziona Permesso</option>}
                    {this.state.filteredMarkTypes.length > 0 ? this.state.filteredMarkTypes.map(item =>
                      <option key={item.id} value={item.id}>{item.label + ' - aree: ' + this.printAreas(item.Areas)}</option>) : <option value={0}>Nessun Permesso disponbile</option>}
                  </Field>
                </div>
              }
            </div>
            {this.state.markLimitReached == true &&
              <span className="text-danger">{this.state.errors}</span>
            }

            {this.state.markRules.id && this.state.markLimitReached == false &&
              <div className="row form-group">

                {/* SEZIONE VIRTUALE */}
                {selectedMarkType.virtualizable &&
                  <div className="col-md-12 checkbox text-left">
                    <label>
                      <Field
                        name="virtual"
                        component="input"
                        type="checkbox"
                      />
                      Contrassegno Virtuale
                    </label>
                  </div>
                }

                {/* SEZIONE PARAMETRI DI CIRCOLAZIONE E SOSTA */}
                {(selectedMarkTypeCategory) &&
                  <React.Fragment>
                    <div className="col-md-4 text-left" style={{ marginBottom: "1em" }}>
                      <label>Circolazione</label>
                      <Field
                        name="circolazione"
                        className="form-control"
                        component="input"
                      />
                    </div>

                    <div className="col-md-4 text-left">
                      <label>Periodo</label>
                      <Field
                        name="periodo"
                        className="form-control"
                        component="input"
                      />
                    </div>
                    <div className="col-md-4 text-left">
                      <label>Sosta</label>
                      <Field
                        name="sosta"
                        className="form-control"
                        component="input"
                      />
                    </div>
                    <Field
                      name="color"
                      component="input"
                      type="hidden"
                    />
                  </React.Fragment>
                }

                {/* SEZIONE DOCUMENTI */}
                {this.state.markTypeDocuments.length > 0 &&
                  <div className="col-md-12 text-left">
                    <label>Documenti necessari:</label>
                    <ul>
                      {this.state.markTypeDocuments.map((item, index) => {
                        return (
                          <li key={`markTypeDocument-${index}`}>
                            {item.name}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                }
              </div>
            }

            {/* SEZIONE RICHIESTA CON SELEZIONE DATA DI INIZIO VALIDITA' */}
            {selectedMarkType && (selectedMarkType.type === MARK_TYPE_FAMILY || (markEnableStartDate && markEnableStartDate.value == "1")) && this.state.markLimitReached == false &&
              <div className="row form-group">
                <div className="col-md-12 text-left">
                  <div className="titolo-categoria">
                    {selectedMarkType.type === MARK_TYPE_FAMILY ?
                      <React.Fragment>
                        <legend className="text-left">Data di nascita del figlio</legend>
                        <p>Il contrassegno Family è valido fino al compimento di <strong>1 anno</strong>.</p>
                      </React.Fragment> :
                      <React.Fragment>
                        <legend className="text-left">Data</legend>
                      </React.Fragment>
                    }
                  </div>
                </div>
                <div className="col-md-6 text-left">
                  {selectedMarkType.type === MARK_TYPE_FAMILY ?
                    <label>Data di Nascita del figlio</label> :
                    <label>Data di inizio validità permesso</label>
                  }
                  {selectedMarkType.type === MARK_TYPE_FAMILY ?
                    <Field
                      placeholderText="GG/MM/AAAA"
                      component={DatePickerFormComponent}
                      name="selectedStartDate"
                      className="mainField"
                      onDateChange={(date) => this.setState({ selectedStartDate: date })}
                      //onDateChange={this.onDateChange}
                      popperPlacement="top-end"
                      required
                    />
                    :
                    <Field
                      placeholderText="GG/MM/AAAA"
                      component={DatePickerFormComponent}
                      name="selectedStartDate"
                      className="mainField"
                      onDateChange={(date) => this.setState({ selectedStartDate: date })}
                      //onDateChange={this.onDateChange}
                      popperPlacement="top-end"
                      required
                    />
                  }

                </div>

                <div className="col-md-6 text-left">
                  {selectedMarkType.type === MARK_TYPE_FAMILY ?
                    <label>Data di scadenza permesso</label> :
                    <label>Data di scadenza permesso</label>
                  }
                  <Field
                    placeholderText={endDate}
                    component={DatePickerFormComponent}
                    name="selectedEndDate"
                    className="mainField DateInput__disabled"
                    onDateChange={this.onDateChange}
                    initialValue={endDate}
                    popperPlacement="top-end"
                    disabled={true}
                  />
                </div>
                <div className="col-md-6">
                  <label> </label>
                  {this.checkSelectedStartDate() && (
                    selectedMarkType.type == MARK_TYPE_FAMILY ?
                      <div className="text-danger">
                        Errore! Il figlio ha già raggiunto un anno di età.
                      </div>
                      :
                      <div className="text-danger">
                        La data di validità deve essere successiva alla mezzanotte della data odierna
                      </div>
                  )
                  }
                </div>
              </div>
            }

            {/* SEZIONE SELEZIONE VEICOLI */}
            {this.state.markRules.id && this.state.markLimitReached == false && this.state.markRules.vehicleLimit > 1 &&
              <div className="row form-group">
                <div className="col-md-12 text-left">
                  <div className="titolo-categoria">
                    <legend className="text-left">Veicolo</legend>
                  </div>
                  <span
                    className={vehicles.length > this.state.markRules.vehicleLimit
                      ? 'text-danger'
                      : 'text-primary'}>
                    {vehicles.length} / {this.state.markRules.vehicleLimit}
                  </span>
                  {' '}
                  <button
                    type="button"
                    className="btn mainBtn"
                    onClick={() => {
                      this.setState({
                        vehicles: [...vehicles, '']
                      }, () => {
                        this.updatePrice()
                        this.props.dispatch(change('backofficeUserDetailMark', `vehicle${vehicles.length - 1}`, ''))
                      })
                    }}>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">Aggiungi Veicolo</Tooltip>}>
                      <FontAwesomeIcon size="1x" icon={solidIcons.faPlusCircle} />
                    </OverlayTrigger>
                  </button>
                </div>
              </div>
            }

            {/* SEZIONE LISTA VEICOLI */}
            {this.state.markRules.id && this.state.markLimitReached == false && vehicles.map((item, index) => (
              <div key={index} className="row form-group">
                {/* TODO  verificare la funzione di aggiunta e rimozione veicoli che non pulisce bene la lista e invia veicoli non deisderati alla creazione  */}
                <div className="col-md-4 text-left">
                  <div className="input-group" style={{ marginTop: 12, marginBottom: 12 }}>
                    <label>Seleziona {index + 1}° Veicolo</label>
                  </div>
                </div>

                <div className="col-md-6 text-left">
                  <div className="input-group" style={{ marginTop: 12, marginBottom: 12 }}>
                    <Field onChange={(e) => this.onVehicleChange(e, index)} name={'vehicle' + index} className="form-control" component="select" required>
                      {(!userVehicles) && <option value={''}>Questo Utente non ha Veicoli</option>}
                      {(userVehicles && userVehicles.length === 0) && <option value={''}>
                        Questo Utente non ha Veicoli
                      </option>}
                      {(userVehicles && userVehicles.length > 0) &&
                        <option value={''}>Seleziona un veicolo</option>}
                      {(userVehicles && userVehicles.length > 0) &&
                        userVehicles.map(item =>
                          <option key={item.id} value={item.id}>
                            {item.plate_number + ' - ' + item.VehiclePowerSupply.name}
                          </option>)}
                    </Field>
                  </div>

                </div>

                <div className="col-md-2 text-left">
                  <div className="input-group" style={{ marginTop: 5, marginBottom: 12 }}>
                    <button
                      onClick={() => this.setState({ vehicles: vehicles.filter((_, i) => i !== index) }, () => this.updatePrice())}
                      type="button"
                      className="btn alertBtn">
                      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">Rimuovi Veicolo</Tooltip>}>
                        <FontAwesomeIcon size="1x" icon={solidIcons.faTrash} />
                      </OverlayTrigger>
                    </button>
                  </div>
                </div>

              </div>)
            )}
            {/* SEZIONE SELEZIONE DATA INIZIO ABBONAMENTO */}
            {/* this.state.markRules.id && selectedMarkType.type != MARK_TYPE_FAMILY && this.state.markLimitReached == false && (
          <div className="row">
            <div className="col-md-6 text-left">
                  <label>Inizio validità</label> 
                <Field
                  placeholderText="GG/MM/AAAA"
                  component={DatePickerFormComponent}
                  name="selectedStartDate" //Questo è il nome del campo che memorizzerà la data di inizio dell'abbonamento. Quando il valore di questo campo cambia, lo utilizzi per aggiornare lo stato ed eseguire i calcoli correlati.(REDUX FORM)
                  className="mainField"
                  onDateChange={this.onDateChange}
                  popperPlacement="top-end"
                  selected={startDateIsValid ? selectedStartDate : null}
                  required                  
                />
            </div>
            <div className="col-md-6 text-left">
                  <label>Scadenza Permesso</label> 
                <Field
                  placeholderText="GG/MM/AAAA"
                  component={DatePickerFormComponent}
                  name="selectedEndDate" //Questo è il nome del campo che memorizzerà la data di fine dell'abbonamento(STATO REDUX FORM).
                  className="mainField"
                  onDateChange={this.onDateChange}
                  selected={endDateIsValid ? selectedEndDate : null}
                  popperPlacement="top-end"
                  required                  
                />
            </div>
          </div>
          )*/}

            {/* SEZIONE PREZZO */}
            {(this.state.markTypeId) && this.state.markLimitReached == false && (
              <div className="row">
                <div className="col-md-12 text-left">
                  <div className="titolo-categoria">
                    <legend className="text-left">Prezzo</legend>
                  </div>
                </div>
                <div className="col-md-4 text-left">
                  <div className="input-group" style={{ marginTop: 12, marginBottom: 12 }}>
                    <div className="input-group-addon">€</div>
                    { }
                    <Field
                      name="total"
                      component="input"
                      type="number"
                      className="form-control"
                      onChange={e => {
                        if (!e.target.value) {
                          this.setState({ selectedTotalPrice: 0 })
                          this.props.change('total', 0)
                        } else {
                          this.setState({ selectedTotalPrice: e.target.value })
                          this.props.change('total', e.target.value)
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 text-left">
                  <div className="input-group" style={{ marginTop: 12, marginBottom: 12 }}>
                    {priceDescr}
                  </div>
                </div>
                {this.isMarksPersonsPerHoursActive() &&
                  <div className="col-md-2 text-left">
                    <div className="input-group" style={{ marginTop: 5, marginBottom: 12 }}>
                      <button type="button" className="btn mainBtn" onClick={() => this.showMarksPersonsPerHouseUnits(true)}>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">Mostra i permessi attivi dei componenti del nucleo abitativo</Tooltip>}>
                          <FontAwesomeIcon size="1x" icon={solidIcons.faUsers} />
                        </OverlayTrigger>
                      </button>
                    </div>
                  </div>
                }
              </div>
            )}

            {this.state.markRules.id && this.state.selectedTotalPrice > 0 && this.state.markLimitReached == false && <div className="row">
              <div className="col-md-6 text-left">
                <label>Totale</label>
                <h4>€ {Number(this.state.selectedTotalPrice).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4>
              </div>
            </div>}

            {/* SEZIONE METODI DI PAGAMENTO */}
            {this.state.markRules.id && this.state.selectedTotalPrice > 0 && this.state.markLimitReached == false && <div className="row">
              <div className="col-md-6 text-left">
                <label>Metodo di Pagamento</label>
                {this.state.enableCash ?
                  <div className="radio">
                    <label><Field component="input" type="radio" name="method" value="SPORTELLO_CONTANTI" required checked={this.verifyChecked("CONTANTI")} onChange={e => this.setPayment(e)} /> CONTANTI</label>
                  </div> :
                  <div className="radio">
                    <label><Field component="input" type="radio" name="method" value="SPORTELLO_CONTANTI" disabled /> CONTANTI</label>
                  </div>
                }
                {this.state.enablePagoPa ?
                  <div className="radio">
                    <label><Field component="input" type="radio" name="method" value="SPORTELLO_PAGOPA" required checked={this.verifyChecked("PAGOPA")} onChange={e => this.setPayment(e)} /> PAGOPA</label>
                  </div> :
                  <div className="radio">
                    <label><Field component="input" type="radio" name="method" value="SPORTELLO_PAGOPA" disabled /> PAGOPA</label>
                  </div>
                }
                {this.state.enableReclaim ?
                  <div className="radio">
                    <label><Field component="input" type="radio" name="method" value="SPORTELLO_BONIFICO" required checked={this.verifyChecked("BONIFICO")} onChange={e => this.setPayment(e)} /> BONIFICO</label>
                  </div> :
                  <div className="radio">
                    <label><Field component="input" type="radio" name="method" value="SPORTELLO_BONIFICO" disabled /> BONIFICO</label>
                  </div>
                }
                {this.state.enableReseller ?
                  <div className="radio">
                    <label><Field component="input" type="radio" name="method" value="RIVENDITORE" required checked={this.verifyChecked("RIVENDITORE")} onChange={e => this.setPayment(e)} /> RIVENDITORE</label>
                  </div> :
                  <div className="radio">
                    <label><Field component="input" type="radio" name="method" value="RIVENDITORE" disabled /> RIVENDITORE</label>
                  </div>
                }
                <div className="radio">
                  <label><Field component="input" type="radio" name="method" value="SPORTELLO_POS" required checked={this.verifyChecked("POS")} onChange={e => this.setPayment(e)} /> POS</label>
                </div>
              </div>
            </div>}

            {this.state.markRules.id && this.state.markLimitReached == false && ((this.state.price && this.state.price.price == 0) || !this.state.price) && <div className="text-primary">
              Permesso gratuito
            </div>}

            {/* SEZIONE MESSAGGGI */}
            {this.props.loading && this.state.markLimitReached == false &&
              <div className="well">
                <div className="row"><h4>Creazione Permesso in Corso...</h4></div>
                <div className="row" style={{ padding: "2em", height: "8em" }}>
                  <Oval secondaryColor="#02afff" color="#174c88" height={40} width="100%" />
                </div>
              </div>
            }

            {errorFetching && this.state.markLimitReached == false && <div className="text-danger">
              ERRORE: {errorFetching}
            </div>}

            {markCreateError && this.state.markLimitReached == false && <div className="text-danger">
              ERRORE: {markCreateError}
            </div>}

            {this.props.markSuccess && this.state.markLimitReached == false && <div>
              <div className="row text-center" style={{ marginTop: "2.5vh", marginBottom: "2vh" }}>
                <div className="col-md-12 text-center">
                  <div className="contrassegno text-center">
                    <h4 className="contrassegno-h text-success text-center" style={{ marginLeft: "10%", width: "80%", color: "green", backgroundColor: "white", padding: "1em" , borderRadius: "15px" }}>Permesso creato correttamente!</h4>
                  </div>
                </div>
              </div>
            </div>}

            {/* SEZIONE PULSANTI */}
            {
              !this.props.markSuccess ?
                errorFetching == null && this.state.markLimitReached == false ?
                  ((this.state.price && this.state.price.price == 0) || this.state.selectedTotalPrice == 0) ?
                  <p>
                    <button type="submit" disabled={this.checkSelectedStartDate()} className="btnSubmit btn mainBtn">
                      Crea Permesso
                    </button>
                  </p>
                  :
                  this.verifyChecked("PAGOPA") || this.verifyChecked("BONIFICO") || this.verifyChecked("RIVENDITORE") || this.verifyChecked("POS") || this.verifyChecked("CONTANTI") ?
                    <p>
                      <button type="submit" disabled={this.checkSelectedStartDate()} className="btnSubmit btn mainBtn">
                        Crea Permesso con pagamento {this.state.paymentSelected != false ? this.state.paymentSelected : this.verifyChecked("PAGOPA") ? "PAGOPA" : this.verifyChecked("BONIFICO") ? "BONIFICO" : this.verifyChecked("RIVENDITORE") ? "RIVENDITORE" : this.verifyChecked("POS") ? "POS" : this.verifyChecked("CONTANTI") ? "CONTANTI" : "IGNOTO"}
                      </button>
                    </p>
                    :
                    <p>
                      <button title="Selezionare un metodo di pagamento" type="submit" disabled={true} className="btnSubmit btn mainBtn" >
                        Crea Permesso con pagamento ?
                      </button>
                    </p>
                : "" // Compilazione...
              : "" // Permesso creato perfettamente
            }
          </div>
        </form>

        <MarksPersonsPerHouseUnitsModal
          show={showMarkPerPersonUnits}
          userId={user.id}
          markTypeId={selectedMarkType != null ? selectedMarkType.id : null}
          onClose={() => { this.showMarksPersonsPerHouseUnits(false) }}
        />
      </React.Fragment>

    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'backofficeUserDetailMark'
})(BackOfficeUserDetailMarkForm))
