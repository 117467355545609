/**
 *  LIBRERIA DI FUNZIONI PER I PERMESSI
 */

import moment from "moment"
import { MARK_DURATION, MARK_ENABLE_START_DATE, MARK_EXPIRATION_DATE } from "./settings"

export const STATUS_ACTIVE = 'ATTIVO'
export const STATUS_CEASED = 'CESSATO'
export const STATUS_RENEWED = 'RINNOVATO'
export const STATUS_EXPIRED = 'SCADUTO'
export const STATUS_WAIT_PAYMENT = 'ATTESA DI PAGAMENTO'
export const STATUS_WAIT_APPROVE = 'ATTESA DI APPROVAZIONE'

export const MARK_STATUS_ACTIVE = 'ACTIVE'
export const MARK_STATUS_ACTIVE_EDITABLE = 'ACTIVE_EDITABLE'
export const MARK_STATUS_CEASED = 'CEASED'
export const MARK_STATUS_PENDING = 'PENDING'
export const MARK_STATUS_PENDING_EDITABLE = 'PENDING_EDITABLE'

export const MARK_TYPE_STANDARD = 'STANDARD'
export const MARK_TYPE_FAMILY = 'FAMILY'
export const MARK_TYPE_HYBRID = 'HYBRID'
export const MARK_TYPE_ELECTRIC = 'ELECTRIC'
export const MARK_TYPE_GREEN = 'GREEN'

export const PRICE_TYPE_PERSON_PER_HOUSING_UNIT = 'PERSON_PER_HOUSING_UNIT'
export const PRICE_TYPE_STANDARD = 'STANDARD'


export const MARK_STATUS_LIST = [
  { id: 1, value: STATUS_ACTIVE },
  { id: 2, value: STATUS_CEASED },
  { id: 3, value: STATUS_RENEWED },
  { id: 4, value: STATUS_EXPIRED },
  { id: 5, value: STATUS_WAIT_PAYMENT },
  { id: 6, value: STATUS_WAIT_APPROVE },
]



/**
 * --------------------------------------------------------------
 * @param {*} mark 
 * @returns 
 */
export function getMarkStatus(mark) {

  const today = moment()  
  let endDateM = moment(mark.endDate)
  const isExpired = today.diff(endDateM, 'days') > 0
 
  var status = { status: '', color: '' }

  if (mark.active) {
    if (mark.ceased) {
      if (mark.status === 'ACTIVE' && mark.renewed && !isExpired) {
        status.status = STATUS_RENEWED
        status.color = '#174c88'
      }
      else {
        status.status = STATUS_CEASED
        status.color = 'red'
      }
    }
    else if (((mark.paid && mark.price > 0) || (parseFloat(mark.price) == 0 || !mark.price))) {
      if ((!mark.ceased && !isExpired)) {
        status.status = STATUS_ACTIVE
        status.color = 'rgb(3, 169, 3)'
      }
      else if (!mark.ceased && isExpired) {
        status.status = STATUS_EXPIRED
        status.color = 'darkred'
      }
    }
    else if (!mark.paid && mark.price > 0) {
      status.status = STATUS_WAIT_PAYMENT
      status.color = '#df8500'
    }
  }
  else {
    status.status = STATUS_WAIT_APPROVE
    status.color = 'orange'
  }

  return status
}

/**
  * ---------------------------------------------------------------------------
  * @param {*} user 
  * @param {*} mark 
  * @returns 
  */
export function getMarkZone(user, mark) {
  let zonesFiltered = null
  let result = ''

  if (mark != null && user != null && mark.MarkType != null && mark.MarkType.Areas != null) {

    if (mark.Category && mark.Category.parkingInDifferentPlace && user.parkSpaceAddress != null) {
      zonesFiltered = mark.MarkType.Areas.filter(item => {
        return item.id === user.parkSpaceAddress.areaId
      })
    }
    else {

      zonesFiltered = mark.MarkType.Areas.filter(item => {
        if (!item.userCheck) {
          return true
        }
        else if (user.residenceArea) {
          return item.id === user.residenceArea.id
        }
        else if (user.workArea) {
          return item.id === user.workArea.id
        }
      })

      if (zonesFiltered == null || zonesFiltered.length <= 0) {
        zonesFiltered = mark.MarkType.Areas
      }

    }
  }

  if (zonesFiltered != null && zonesFiltered.length > 0) {
    for (let index in zonesFiltered) {
      result = result.length <= 0 ? zonesFiltered[index].code : result + ' , ' + zonesFiltered[index].code
    }
  }

  return result
}

/**
  * --------------------------------------------------------------------------
  * @param {*} documentTypeId 
  * @returns 
  */
export function isTypeDocumentSelected(documentTypeId, selectedDocuments) {
  var selected = false

  if (selectedDocuments) {
    var foundDoc = selectedDocuments.filter(selectedDoc => selectedDoc.DocumentTypeId === documentTypeId)

    if (foundDoc && foundDoc.length > 0)
      selected = true

  }

  return selected
}

/**
 * --------------------------------------------------------------------------
 * @returns 
 */
export function areObligatoryDocsSelected(documents, selectedDocuments) {
  var result = true;

  var mandatoryDocs = documents.filter(item => item.mandatory)
  mandatoryDocs.map(doc => {
    if (!isTypeDocumentSelected(doc.documentTypeId, selectedDocuments))
      result = false
  })

  return result
}

/**
 * --------------------------------------------------------------------------
 * @returns 
 */
export function areObligatoryDocsTypeSelected(documentTypes, selectedDocuments) {
  var result = true;

  var mandatoryDocs = documentTypes.filter(item => item.mandatory)
  mandatoryDocs.map(docType => {
    if (!isTypeDocumentSelected(docType.id, selectedDocuments))
      result = false
  })

  return result
}

/**
 * --------------------------------------------------------------------------
 * @param {*} document 
 * @param {*} selectedDocuments 
 * @returns 
 */
export function getDocumentColor(document, selectedDocuments) {
  var isSelected = isTypeDocumentSelected(document.documentTypeId, selectedDocuments)
  var color = 'green'
  if (!isSelected) {
    if (document.mandatory) {
      color = 'red'
    }
    else if (!document.mandatory) {
      color = 'orange'
    }
  }

  return color
}


/**
 * --------------------------------------------------------------------------
 * @param {*} document 
 * @param {*} selectedDocuments 
 * @returns 
 */
export function getDocumentTypeColor(documentType, selectedDocuments) {
  var isSelected = isTypeDocumentSelected(documentType.id, selectedDocuments)
  var color = 'green'
  if (!isSelected) {
    if (documentType.mandatory) {
      color = 'red'
    }
    else if (!documentType.mandatory) {
      color = 'orange'
    }
  }

  return color
}

/**
 * --------------------------------------------------------------
 * calcola la data di fine validità di un permesso da creare
 * @param {*} settings 
 * @param {*} selectedMarkType 
 * @param {*} selectedStartDate 
 * @returns ( moment )
 */
export function getMarkExpirationDate(settings, selectedMarkType, selectedStartDate) {

  var expirationDate = null

  if (selectedMarkType == null || settings == null) {
    expirationDate = null
  }
  else if (selectedMarkType.type === MARK_TYPE_FAMILY) {
    var duration = parseInt(settings[selectedMarkType.id].value)

    if (selectedStartDate != null)
      expirationDate = moment(selectedStartDate).add(duration, 'days')
  }
  else if (settings[selectedMarkType.id].name.includes('MarkExpirationDate')) {
    expirationDate = moment(settings[selectedMarkType.id].value)
  }
  else if (settings[selectedMarkType.id].name.includes('MarkDuration')) {

    if (settings[MARK_ENABLE_START_DATE].value == "1" && selectedStartDate != null) {
      expirationDate = moment(selectedStartDate).add(parseInt(settings[selectedMarkType.id].value), 'days')
    }
    else {
      expirationDate = moment().add(parseInt(settings[selectedMarkType.id].value), 'days')
    }

  }

  return expirationDate

}


/**
 * --------------------------------------------------------------
 * Calcola e restituisce la data di fine validità di un permesso già 
 * creato ma non ancora abilitato
 * @param {*} mark 
 * @param {*} settingList 
 * @returns ( moment )
 */
export function getSelectedEndDate(mark, settingList) {

  var selectedEndDate = null
  var settings = settingList.filter(setting => (setting.name == MARK_DURATION + mark.MarkType.id || setting.name == MARK_EXPIRATION_DATE + mark.MarkType.id))

  // CASO VALIDO ANCHE PER I MARK_TYPE_FAMILY
  if (mark != null && mark.selectedStartDate != null && mark.MarkType != null) {
    
    
    if (settings != null && settings.length > 0) {
      if (settings[0].name == MARK_DURATION + mark.MarkType.id) {       
        selectedEndDate = moment(mark.selectedStartDate).add(parseInt(settings[0].value, 10), "days")
      }
      else if (settings[0].name == MARK_EXPIRATION_DATE + mark.MarkType.id) {        
        selectedEndDate = moment(settings[0].value);    
      }
    }
  }
  else {    
    if (settings != null && settings.length > 0) {
      if (settings[0].name == MARK_DURATION + mark.MarkType.id) {
        selectedEndDate = moment().add(parseInt(settings[0].value, 10), "days")
      }
      else if (settings[0].name == MARK_EXPIRATION_DATE + mark.MarkType.id) {
        selectedEndDate = moment(settings[0].value)
      }
    }
  }

  return selectedEndDate
}

/**
 * --------------------------------------------------------------
 * Calcola e restituisce la data di inizio validità di un permesso
 * già creato ma non ancora abilitato
 * @param {*} mark 
 * 
 * @returns ( moment )
 */
export function getSelectedStartDate(mark) {
  var sSD = mark.selectedStartDate
  console.log('marks getSelectedStartDate sSD:', sSD)//
  var selectedStartDate = null
  if (mark && (sSD != undefined)) {
    if (mark && (sSD != null)) {
      selectedStartDate = sSD
      console.log('marks getSelectedStartDate selectedStartDate:', selectedStartDate)//
    } else {
      selectedStartDate = moment()
      console.log('marks getSelectedStartDate selectedStartDate2:', selectedStartDate)
    }
  }
  if (mark != null && mark.MarkType == MARK_TYPE_FAMILY && selectedStartDate == null) {
    selectedStartDate = moment()
    console.log('marks getSelectedStartDate selectedStartDate3:', selectedStartDate)
  } else if (mark != null && sSD != null && mark.MarkType != null) {

    if (moment(sSD).isBefore(moment()) && MARK_ENABLE_START_DATE) {
      selectedStartDate = moment(sSD)
      console.log('marks getSelectedStartDate selectedStartDate6:', selectedStartDate)
    } else if (moment(sSD).isBefore(moment()) && !MARK_ENABLE_START_DATE) {
      selectedStartDate = moment()
      console.log('marks getSelectedStartDate selectedStartDate4:', selectedStartDate)//
    } else {
      selectedStartDate = moment(sSD)
      console.log('marks getSelectedStartDate selectedStartDate5:', selectedStartDate)
    }
  }

  return selectedStartDate
}

