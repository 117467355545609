import React, {Component}    from 'react'
import PropTypes             from 'prop-types'
import MarkRequest           from './MarkRequest'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'
import { connect }           from 'react-redux'
import { scroller }          from 'react-scroll'
import moment                from 'moment'
import { MARK_TYPE_FAMILY } from 'libs/marks'
import { removeRepeatedDocuments } from 'libs/documents'


class MarkRequestContainer extends Component {
  static propTypes = {
    auth: PropTypes.object
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  constructor (props) {
    super(props)
    this.state = {
      markLimitReached: false,
      initialValues: {},
      user: {},
      settings: {},
      markTypes: [],
      addresses: [],
      markRules: {},
      documents: [],
      loading: true,
      price: null,
      selectedMarkType: null,
      selectedVehicles: [],
      selectedDocuments: [],
      errors: null,            
      submitting: false,
      selectedUserCategory: null,      
      selectedStartDate: null
    }
    
    this.onMarkTypeSelected = this.onMarkTypeSelected.bind(this)
    this.onVehicleSelected = this.onVehicleSelected.bind(this)
    this.onVehicleAdd = this.onVehicleAdd.bind(this)
    this.onVehicleRemove = this.onVehicleRemove.bind(this)
    this.onDocumentAdd = this.onDocumentAdd.bind(this)
    this.onDocumentRemove = this.onDocumentRemove.bind(this)
    this.onVehicleOwnershipTypeChange = this.onVehicleOwnershipTypeChange.bind(this)
    this.onVehiclePowerSupplyChange = this.onVehiclePowerSupplyChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onDocumentChange = this.onDocumentChange.bind(this)
    this.onCloseFileManager = this.onCloseFileManager.bind(this)
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  async fetchInitialData () {
    this.setState({loading: true})
    const userResponse     = await SostaOnlineApiService.fetchUserMe()
    const settingsResponse = await SostaOnlineApiService.fetchPublicSettings()
    const { data: user } = userResponse
    const { categories, workArea, residenceArea } = user

    let areaIds = []
    if (workArea) {
      areaIds.push(workArea.id)
    }

    if (residenceArea) {
      areaIds.push(residenceArea.id)
    }

    
    const markTypesResponse = await SostaOnlineApiService.fetchMarkTypesByCategoryAndAreaIds(
      categories.map(item => item.id + ','),
      areaIds.map(item => item + ',')
    ) 

    this.fetchAddresses()
    this.setState({
      user: user,
      settings: settingsResponse.data.reduce((acc, item) => {
        const itemNameSplitted = item.name.split('_')
        return {
          ...acc,
          [itemNameSplitted[itemNameSplitted.length - 1]]: item
        }
      }, {}),
      markTypes: markTypesResponse.data,            
      loading: false
    })
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  fetchAddresses () {
    this.setState({loading: true})
    SostaOnlineApiService.fetchAddresses().then((data) => {
      this.setState({
        addresses: data.data,
        loading: false
      })
    })
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  getUserMatchingCategories (categories) {
    return this.state.user.categories.filter(item => categories.filter(cI => cI.id === item.id).length > 0)
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  async componentDidMount () {
    await this.fetchInitialData()
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  getUserMarksByTypeId (markType) {
    const today = moment()
    return this.state.user.marks.filter(item => {
      const endDate = moment(item.endDate)
      const isExpired = today.diff(endDate, 'days') > 0
      return item.markTypeId === markType.id && !item.archived && !item.ceased && !isExpired
    })
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  printVehiclesPowerSupplies (vehicles) {
    let string = ''
    vehicles.map((item, index) => {
      string = string + item.VehiclePowerSupply.id

      if (index < vehicles.length - 1) {
        string = string + ','
      }
    })
    return string
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  async updatePrice () {

    this.setState({errors: null})

    try {
      const matchingCategories = this.getUserMatchingCategories(this.state.selectedMarkType.Categories)
      let promises = matchingCategories.map((item) => {
        const p = SostaOnlineApiService.fetchMarkPrice(
          this.state.user.id,
          this.state.selectedMarkType.id,
          item.id,
          null,
          this.printVehiclesPowerSupplies(this.state.selectedVehicles),
          this.state.selectedStartDate
        )
        return p
      })

      const prices = await Promise.all(promises)
      if (prices.filter(item => item.result !== 'ok').length > 0) {
        this.setState({errors: 'errore durante la ricezione dei prezzi'})
      }

      let lowestPrice = prices[0].data
      prices.map(item => {
        if (item.data.price < lowestPrice) {
          lowestPrice = item.data
        }
      })

      this.setState({price: lowestPrice})
    } 
    catch (ex) {
      console.log("ERROR",ex)

      var message = 'Errore durante l\'elaborazione dei prezzi'

      if ( ex.result == "ko" && ex.error != null )
        message = ex.error

      this.setState({errors: message})
    }
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  async onMarkTypeSelected (markType) {
    this.setState({errors: undefined})
    
    try {
      const rules = await SostaOnlineApiService.fetchMarkRulesByMarkType(markType.id)
      if (rules.data.length === 0) {
        this.setState({errors: 'Impossibile scaricare le regole del permesso'})
        return
      }

      const documentsTypes = await SostaOnlineApiService.fetchDocumentTypesByMarkTypeId(markType.id)
      
      if (this.getUserMarksByTypeId(markType).length >= rules.data[0].marksLimit) {
        this.setState({
          markLimitReached: true,
          errors: 'Hai già superato il numero massimo di permessi attivi per il tuo tipo di utente'
        })       
        return
      }

      this.setState(
        {
          markLimitReached: false,
          markRules: rules.data[0],
          documents: documentsTypes.data.map(item => {
            
            let mandatory = false
            
            if ( 
              item.MarkTypes && 
              item.MarkTypes.length > 0 && 
              item.MarkTypes[0] && 
              item.MarkTypes[0].MarkTypesDocuments ) 
            {
              mandatory = item.MarkTypes[0].MarkTypesDocuments.mandatory
            } 
                                
            return {
              documentTypeId: item.id,              
              typeName: item.name,
              typeLabel: item.label,
              mandatory: mandatory,
              vehiclePlateNumber: null,
              document: null
            }
          }),
          selectedMarkType: markType,
          selectedDocuments: [],
          selectedVehicles: [],
          price: null,
          //selectedStartDate: null
        }, 
        () => {
          if (this.state.selectedVehicles.filter(item => item !== '').length > 0) {
            this.updatePrice()
          }
        
          scroller.scrollTo(markType.type === MARK_TYPE_FAMILY ? 'child-borndate' : 'step-two', {
            duration: 1000,
            delay: 100,
            smooth: true
          })

        }
      )
    } 
    catch (ex) {
      this.setState({error: 'Errore imprevisto'})
    }
  }
  
  /**
   * --------------------------------------------------------------------------
   * 
   */
  onVehicleSelected ( vehicleId ) {
    
    if (this.state.markRules.vehicleLimit === this.state.selectedVehicles.length) {
      return
    }
            
    var newVehicle = this.state.user.vehicles.filter( ( vehicle  ) => vehicle.id === vehicleId  )

    if ( newVehicle.length > 0 ) {      
      var newSelectedVehicles = this.state.selectedVehicles       
      newSelectedVehicles.push(newVehicle[0])
      
      this.setState({selectedVehicles: newSelectedVehicles}, async () => {    
        await this.updatePrice()
      })
    }

    scroller.scrollTo( 'step-two', {
      duration: 1000,
      delay: 100,
      smooth: true
    })

  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  onVehicleRemove (index) {
    this.setState({selectedVehicles: this.state.selectedVehicles.filter((_, i) => i !== index)}, () => {
      if (this.state.selectedVehicles.length > 0) {
        this.updatePrice()
      } 
      else {
        this.setState({price: null})
      }
    })
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  async onVehicleAdd (vehicle) {    
    await this.fetchInitialData()
    this.onVehicleSelected(vehicle.id)   

    scroller.scrollTo( 'step-two', {
      duration: 1000,
      delay: 100,
      smooth: true
    })
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  onCloseFileManager() {    
    const {selectedDocuments} = this.state
    const newDocuments = selectedDocuments.filter( (item ) =>  item.id != null && item.id != -1 )
    this.setState({selectedDocuments: newDocuments})
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} document 
   * @param {*} index 
   */
  onDocumentChange (document,index) {    

    const {selectedDocuments} = this.state    

    if ( selectedDocuments.findIndex( doc =>  doc.id == document.id  ) < 0 ) {
      
      const newDocuments = selectedDocuments.map((item,index1) => {

        if ( index === index1 || item.id === -1  ) {

          this.setState({          
            initialValues: {
              ...this.state.initialValues,
              ['documentFile-' + index + "-" + document.id ] : {
                ...document          
              }
            }
          })

          return document
        } 
        else {        
          return item
        }
      })
      
      this.setState({
        selectedDocuments: [...newDocuments]
      })
    }
    else {
      console.log("ELEMENTO DOPPIO")
      const newDocuments = selectedDocuments.filter( (item ) =>  item.id != null && item.id != -1 )
      this.setState({selectedDocuments: newDocuments})      
    }

    scroller.scrollTo( 'step-final', {
      duration: 1000,
      delay: 100,
      smooth: true
    })
    
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} index 
   */
  onDocumentRemove (index) {

    console.log("onDocumentRemove")

    this.setState({selectedDocuments: this.state.selectedDocuments.filter((_, i) => i !== index)})
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  onDocumentAdd () {

    console.log("onDocumentAdd")

    var docs =  this.state.selectedDocuments.map( doc => { doc.openFileManager = false ; return doc  } )
    docs = [...docs, { 'openFileManager': true, id:-1 } ]
    this.setState({ 'selectedDocuments': docs})

    scroller.scrollTo( 'step-final', {
      duration: 1000,
      delay: 100,
      smooth: true
    })
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} e 
   * @param {*} index 
   */
  onVehicleOwnershipTypeChange (e, index) {
    let newSelectedVehicles = [...this.state.selectedVehicles]
    newSelectedVehicles[index].VehicleOwnershipType = {id: e.target.value}
    this.setState({selectedVehicles: newSelectedVehicles}, () => this.updatePrice())
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} e 
   * @param {*} index 
   */
  onVehiclePowerSupplyChange (e, index) {
    let newSelectedVehicles = [...this.state.selectedVehicles]
    newSelectedVehicles[index].VehiclePowerSupply = {id: e.target.value}
    this.setState({selectedVehicles: newSelectedVehicles}, () => this.updatePrice())
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} values 
   * @returns 
   */
  async onSubmit (values) {
    const { submitting, selectedVehicles, selectedMarkType, price, selectedDocuments } = this.state

    if (submitting) {
      return
    }

    try {
      this.setState({submitting: true, errors: null})

      const response = await SostaOnlineApiService.createMarkMe({
        vehicles: selectedVehicles,
        markTypeId: selectedMarkType.id,
        categoryId: price.category.id,
        documents: selectedDocuments.map(item => item.id),
        selectedStartDate: this.state.selectedStartDate
      })

      if (response.result !== 'ok') {
        this.setState({
          errors: 'Errore durante la richiesta del permesso',
          submitting: false
        })
      } 
      else {
        this.setState({
          submitting: false
        })
        this.props.history.push('/user-area/marks')
      }

    } 
    catch (e) {      
      this.setState({submitting: false, errors: e.error || 'Errore imprevisto durante la richiesta del permesso'})
    }
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} data 
   */
  onDateChange = (data) => {       

    if( this.state.selectedMarkType.type != MARK_TYPE_FAMILY && moment(data).isBefore(moment()) ) {
      data = moment().format()
    }
        
    this.setState({
      selectedStartDate: moment(data).format()
    })

    return data
    
  }

  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  render () {
    const {
      markTypes,
      loading,
      selectedMarkType,
      documents,
      errors,
      user,
      selectedVehicles,            
      markRules,
      price,
      submitting,            
      selectedDocuments,
      settings,
      initialValues,
      markLimitReached
    } = this.state

    if ( errors && errors.length > 0 ) {
      scroller.scrollTo('errors', {
        duration: 1000,
        delay: 100,
        smooth: true
      })
    }

    return (
      <React.Fragment>
        { !loading && (
          <MarkRequest            
            user={user}
            initialValues={initialValues}            
            onMarkTypeSelected={async (item) => { await this.onMarkTypeSelected(item) }}
            selectedMarkType={selectedMarkType}
            selectedVehicles={selectedVehicles}
            settings={settings}
            documents={documents}            
            errors={errors}
            onVehicleSelected={this.onVehicleSelected}                        
            loading={loading}
            onVehicleAdd={this.onVehicleAdd}
            onVehicleRemove={this.onVehicleRemove}            
            price={price}
            onSubmit={this.onSubmit}
            markRules={markRules}
            submitting={submitting}
            markTypes={markTypes}
            selectedDocuments={selectedDocuments}
            onDocumentAdd={this.onDocumentAdd}
            onDocumentRemove={this.onDocumentRemove}
            onDocumentChange={this.onDocumentChange}
            onDateChange={this.onDateChange}
            selectedStartDate={this.state.selectedStartDate}
            onCloseFileManager={this.onCloseFileManager}
            markLimitReached={markLimitReached}
          />
        )}        
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MarkRequestContainer)
